class SearchPopup extends HTMLElement {
  constructor() {
    super();
    this.input = this.querySelector('[data-role="search-input"]');
    this.toggleButton = this.querySelectorAll('[data-role="toggle-result"]');
    this.productContainer = this.querySelector('[data-slot="products"]');
    this.articleContainer = this.querySelector('[data-slot="articles"]');
    this.resultContainer = this.querySelector(
      '[data-role="results-container"]'
    );
    this.clearButton = this.querySelector('[data-role="clear-input"]');
    this.emptySearch = this.querySelector('[data-role="empty-search"]');

    this.input.addEventListener(
      "input",
      this.debounce((event) => {
        this.onChange(event);
      }, 300).bind(this)
    );
    this.toggleButton.forEach((el) => {
      el.addEventListener("click", () => {
        this.toggleResult.call(this, el.dataset.value);
      });
    });
    this.clearButton.addEventListener("click", this.clearResult.bind(this));

    document.addEventListener("click", (e) => {
      if (
        e.target.dataset.role == "search-toggle" ||
        e.target.closest('[data-action="search-toggle"]')
      ) {
        this.popupToggle.call(this, e);
      }
    });
    this.announcementBarVisibility();
  }
  connectedCallback() {
    let announcementBar = document.querySelector("[announcement-bar]");
    if (announcementBar) {
      let height = announcementBar.clientHeight;
      this.style.top = `${height}px`;
    }
  }
  clearResult() {
    this.renderContent("");
    this.input.value = "";
    this.resultContainer.classList.remove("active");
  }
  toggleResult(slot) {
    Array.from(this.toggleButton).forEach((el) => {
      el.dataset.value == slot
        ? el.classList.add("active")
        : el.classList.remove("active");
    });
    if (slot == "products") {
      this.productContainer.classList.add("active");
      this.articleContainer.classList.remove("active");
    } else {
      this.articleContainer.classList.add("active");
      this.productContainer.classList.remove("active");
    }
  }
  getSearchResults(searchTerm) {
    this.classList.add("loading");
    this.emptySearch.classList.add("hidden");
    this.resultContainer.classList.add("hidden");
    this.resultContainer.classList.remove("active");
    fetch(
      `/search/suggest?q=${searchTerm}&resources[type]=product,article&section_id=predictive-search-v2`
    )
      .then((resp) => {
        if (resp.ok) {
          this.emptySearch.classList.add("hidden");
          return resp.text();
        } else {
          this.classList.remove("loading");
          this.emptySearch.classList.remove("hidden");
          throw new Error(resp.status);
        }
      })
      .then((text) => {
        let resultHtml = new DOMParser().parseFromString(text, "text/html");
        if (resultHtml.querySelector("#predictive-search-results")) {
          this.renderContent(resultHtml);
          this.emptySearch.classList.add("hidden");
          this.resultContainer.classList.remove("hidden");
        } else {
          this.emptySearch.classList.remove("hidden");
          this.resultContainer.classList.remove("active");
          this.resultContainer.classList.add("hidden");
        }
        this.classList.remove("loading");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  renderContent(html) {
    //console.log(html)
    let contentToRender = [
      '[data-role="suggestion-text"]',
      '[data-role="suggestion-products"]',
      '[data-role="suggestion-articles"]',
      '[data-role="result-articles-btn"]',
      '[data-role="result-products-btn"]',
      '[data-role="results-articles-count"]',
      '[data-role="results-products-count"]',
    ];
    contentToRender.forEach((el, ind) => {
      html != "" && html.querySelector(el)
        ? (this.querySelector(el).innerHTML = html.querySelector(el).innerHTML)
        : (this.querySelector(el).innerHTML = "");
      html == "" || !html.querySelector(el)
        ? (this.querySelector(el).innerHTML = "")
        : "";
    });
    this.resultContainer.classList.add("active");
  }
  onChange() {
    const searchTerm = this.input.value.trim();
    this.getSearchResults(searchTerm);
  }
  debounce(fn, wait) {
    let t;
    return (...args) => {
      clearTimeout(t);
      t = setTimeout(() => fn.apply(this, args), wait);
    };
  }
  popupToggle(e) {
    e.preventDefault();
    this.classList.toggle("active");
    if (this.classList.contains('active')) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = 'auto';
    }
    this.input.focus();
    // this.clearResult("");
  }
  announcementBarVisibility() {
    let announcementBar = document.querySelector('[data-role="announcement"]');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const intersecting = entry.isIntersecting;
        if (intersecting) {
          this.style.top = announcementBar.clientHeight + "px";
        } else {
          this.style.top = "0px";
        }
      });
    });
    observer.observe(announcementBar);
  }
}
customElements.define("search-popup", SearchPopup);
//console.log("search popup loaded")
